.logincont {
  background: url("sunset.jpg");
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

#log {
  /* display: none; */
  border: 2px solid #ffffff;
  overflow: hidden;
  margin-top: 8vh;
  margin-right: 10px;
  height: 84vh;
  min-height: 550px;
  border-radius: 30px;

  -webkit-box-shadow: 0px 0px 37px 13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 37px 13px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 37px 13px rgba(0, 0, 0, 0.75);

  background: -moz-linear-gradient(
    180deg,
    rgb(145, 151, 168) 0%,
    rgba(145, 151, 168, 0.75) 8%,
    rgba(145, 151, 168, 0.75) 9%,
    rgba(3, 8, 7, 0.7) 32%,
    rgba(3, 8, 7, 0.6) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgb(145, 151, 168) 0%,
    rgba(145, 151, 168, 0.75) 8%,
    rgba(145, 151, 168, 0.75) 9%,
    rgba(3, 8, 7, 0.7) 32%,
    rgba(3, 8, 7, 0.6) 100%
  );
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(
    180deg,
    rgb(145, 151, 168) 0%,
    rgba(145, 151, 168, 0.75) 8%,
    rgba(145, 151, 168, 0.75) 9%,
    rgba(3, 8, 7, 0.7) 32%,
    rgba(3, 8, 7, 0.6) 100%
  );
  /* opera 11.10+ */
  background: -ms-linear-gradient(
    180deg,
    rgb(145, 151, 168) 0%,
    rgba(145, 151, 168, 0.75) 8%,
    rgba(145, 151, 168, 0.75) 9%,
    rgba(3, 8, 7, 0.7) 32%,
    rgba(3, 8, 7, 0.6) 100%
  );
  /* ie10+ */
  background: linear-gradient(
    180deg,
    rgb(145, 151, 168) 0%,
    rgba(145, 151, 168, 0.75) 8%,
    rgba(145, 151, 168, 0.75) 9%,
    rgba(3, 8, 7, 0.7) 32%,
    rgba(3, 8, 7, 0.6) 100%
  );
}
.rounded-circle {
  width: 25%;
  /* margin-top: 3.5vh; */
  background-color: rgba(5, 5, 5, 0.2);
  padding: 5px;
}

.logincont label {
  color: #ffffff;
  font-size: 0.9rem;
  font-weight: lighter;
}
#log button {
  -webkit-box-shadow: 0px 0px 37px 13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 37px 13px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 37px 13px rgba(0, 0, 0, 0.75);
}

.logo {
  width: 120%;
  height: 120px;
  margin-left: -9%;
}
.cont {
  padding: 0 40px;
}
.checkbox {
  margin-top: 10px;
  /* font-size: 2.5vh; */
}

/* footer */
.login-footer {
  font-size: 12px;
  padding: 0 5px 0 5px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  color: #ffffff;
}
.version {
  float: right;
}

@media screen and (max-width: 767px) and (min-width: 576px) {
  .third_col {
    padding: 0 25%;
  }
}

@media screen and (max-width: 575px) {
  #log {
    min-height: 650px;
    margin-bottom: 10vh;
    min-width: 300px;
  }
  .logo {
    margin-left: -10%;
    height: 120px;
    /* margin-top: 30px; */
  }
  body {
    background-attachment: scroll;
  }
  .login-footer {
    position: static;
    padding: 0;
    font-size: 10px;
  }
}

@media screen and (max-height: 650px) {
  #log {
    min-height: 600px;
    margin-bottom: 7vh;
  }
  body {
    background-attachment: scroll;
  }
  .login-footer {
    position: static;
    padding: 0;
  }
  .logincont {
    height: 100%;
  }
}

@media screen and (min-height: 700px) and (max-height: 900px) {
  .rounded-circle {
    width: 40%;
  }
  .logo {
    height: 20%;
    margin-top: 2%;
  }
  .inputbox {
    height: 5vh;
  }
}

@media screen and (min-height: 900px) {
  #log {
    margin: 15vh 0 0 0;
    height: 70vh;
    font-size: 2vh;
  }
  .logo {
    height: 25%;
  }
  .inputbox {
    height: 4vh;
  }
  .btn-primary {
    font-size: 2vh;
    line-height: 2vh;
  }
  input[type="checkbox"] {
    width: 1.5vh;
    height: 1.5vh;
  }
}
