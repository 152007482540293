.loading,
.loading1 {
  z-index: 1000;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading1 h2,
.loading h2 {
  text-align: center;
}

.loading1 img {
  width: 200px;
  /* filter: blur(2px); */
}
